.page {
    width: 100%;
    height: 100%;
    position: absolute;


}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #F3AA61;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;

}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}
.about-me-background {
    padding-right: 1rem;
    background-image: -webkit-linear-gradient(-45deg,transparent,transparent 20.5805011712%,#F3AA61 0,#F3AA61 29.4194988288%,transparent 0,transparent 70.5805011712%,#F3AA61 0,#F3AA61 79.4194988288%,transparent 0,transparent);
    background-size: 0.5rem 0.5rem;
    background-repeat: repeat;
    background-position: 0 0;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 12.625rem;
    line-height: 1;
    letter-spacing: -.625rem;
    width: -moz-fit-content;
    width: fit-content;
    height: fit-content;
    opacity: .6;
    left: 5%;
    position: absolute;
    top: 10%;
}


.about-page,
.contact-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: middle;
        width: 35%;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 53px;
            color: #F3AA61;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
        }

        p {
            font-size: 13px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;
        }
    }
    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

@media screen and (max-width:1200px) {
    .page {
        position: initial;
    }

    .container {
        position: initial;
        height: auto;
        min-height: auto;

        &.about-page,
        &.contact-page {
            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
    
            }
        }
    }
}